import {
  ActionData,
  AndOrCondition,
  AppFilter,
  CountryCodeType,
  FlowActionData,
  FlowData,
  FlowStatus,
  GetFlowDocument,
  RequestData,
  RequestStatus,
  TagCodeType,
  useFinishFlowAction,
  useGetActiveFlowLazyQuery,
  useGetFlowLazyQuery,
  useGetRequestLazyQuery,
  useSendRequest,
  useStartFlow,
  useUpdateActionState,
  useUpdateRequest,
} from '../types/bizcuitApi';
import { ORCHESTRATOR_PANIC_URL } from '../pages/orchestrator/config';
import { useNavigate } from 'react-router-dom';
import { useErrorDialog } from './useErrorDialog';
import { ApolloError } from '@apollo/client/errors';
import { useCallback } from 'react';
import { Paths } from 'src/tokens';
import { useErrorTranslation } from './useErrorTranslations';
import { useAtom } from 'jotai';
import { orchestratorAtom } from 'src/contexts/orchestrator-global-state/orchestrator-atoms/orchestrator-global-atoms';
import { gql, useApolloClient } from '@apollo/client';
import { isEmpty, logger } from '../utils';
import { Request, Requests } from 'src/types/orchestrator';
import { produce } from 'immer';
import * as Sentry from '@sentry/react';
import { useUserStore } from './index';
import { FlowFilter } from 'src/pages/authorize-user/types';

export const isFlowIdValid = (flowId = '') => /^[a-z0-9-]+$/.test(flowId);
export const isFlowActionIdValid = (actionId = '') => /^[a-z0-9-]+$/.test(actionId);

type UseOrchestratorResponse = {
  redirectUrl?: string;
  called: Record<string, boolean>;
  methods: Record<string, (props: any) => any>;
  loading: Record<string, boolean>;
  error: Record<string, ApolloError | undefined>;
};

const {
  orchestrator: { root, segments },
} = Paths;

const getOverviewUrl = (flowId: string) => {
  return `${root}${segments.overview}`.replace(':flowId', flowId);
};

const getSuccessScreenUrl = (flowId: string) =>
  (Paths.orchestrator.root + Paths.orchestrator.segments.success).replace(':flowId', flowId);

const findActionsByStatus = (actions: FlowActionData[], statuses: FlowStatus[]): ActionData[] => {
  return produce(actions, (draft) => {
    const availableActions: ActionData[] = [];

    for (const flowOrAction of draft) {
      if (flowOrAction.type === 'flow') {
        const flow = flowOrAction as FlowData;
        const subFlowAvailableActions = findActionsByStatus(flow.actions, statuses);

        if (subFlowAvailableActions.length) availableActions.push(...subFlowAvailableActions);

        continue;
      }

      if (!statuses.includes(flowOrAction.status)) continue;

      availableActions.push(flowOrAction as ActionData);
    }

    return availableActions;
  }) as ActionData[];
};

const getNextActionsInFlow = (flow: Pick<FlowData, 'actions'>) => {
  const availableActions = findActionsByStatus(flow.actions, [
    FlowStatus.Available,
    FlowStatus.Blocked,
  ]);

  return [...availableActions];
};

const getNextAvailableSubFlow = (flow: FlowData): FlowData | undefined => {
  return flow.actions.find((action) => {
    return action.status === 'available' && action.type === 'flow';
  }) as FlowData | undefined;
};

export const useOrchestrator = () => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const errorDialog = useErrorDialog();
  const [updateActionStateMutation, { ...updateActionStateProps }] = useUpdateActionState();
  const [finishFlowActionMutation, { ...finishFlowActionProps }] = useFinishFlowAction();
  const [getActiveFlowQuery, { ...getActiveFlowProps }] = useGetActiveFlowLazyQuery();
  const [getFlowQuery, { ...getFlowProps }] = useGetFlowLazyQuery();
  const [updateRequestMutation, { ...updateRequestProps }] = useUpdateRequest();
  const [getRequestQuery, { ...getRequestProps }] = useGetRequestLazyQuery();
  const [sendRequest, { ...sendRequestProps }] = useSendRequest();
  const [startFlowMutation, { ...startFlowProps }] = useStartFlow();
  const [orchestratorState, setOrchestratorState] = useAtom(orchestratorAtom);
  const genericError = useErrorTranslation('somethingWentWrong');
  const invalidFlowId = useErrorTranslation('orchestratorInvalidFlowId');
  const invalidFlowActionId = useErrorTranslation('orchestratorInvalidFlowActionId');
  const requestId = orchestratorState?.approverRequest?.requestId;
  const {
    state: { user },
  } = useUserStore();

  const fetchPolicy = 'no-cache';

  const getNavigationUrl = useCallback((flow: FlowData | null | undefined) => {
    if (!flow) {
      return ORCHESTRATOR_PANIC_URL;
    }

    if (flow.flowType === 'freeform') {
      return getOverviewUrl(flow.id);
    }

    const nextAvailableSubFlow = getNextAvailableSubFlow(flow);
    if (nextAvailableSubFlow?.flowType === 'freeform') {
      return getOverviewUrl(nextAvailableSubFlow.id);
    }

    const nextAction = { ...getNextActionsInFlow(flow)[0] };
    const nextActionState = nextAction?.state ? JSON.parse(nextAction.state) : null;
    let nextActionUrl = nextAction.url;

    if (nextActionState?.partnerId) {
      nextActionUrl = nextAction.url.replace(':partnerId', nextActionState.partnerId);
    }

    return nextActionUrl || getOverviewUrl(flow.id);
  }, []);

  const getNextAction = useCallback((flow: FlowData) => {
    const nextAction = { ...getNextActionsInFlow(flow)[0] };
    const nextActionState = nextAction?.state ? JSON.parse(nextAction.state) : null;

    if (nextActionState?.partnerId) {
      nextAction.url = nextAction.url.replace(':partnerId', nextActionState.partnerId);
    }

    return nextAction;
  }, []);

  //region Methods
  const getAction = useCallback(({ flow, actionId }: { flow: FlowData; actionId: string }) => {
    let result: ActionData | undefined;

    for (const action of flow.actions) {
      if (action.__typename === 'FlowData') {
        const response = getAction({ flow: action, actionId });

        if (!response) continue;

        result = response;
        break;
      }
      if (action.__typename !== 'ActionData') continue;
      if (action.id !== actionId) continue;

      result = action;
      break;
    }

    return result;
  }, []);

  const getAppFilter = useCallback(
    ({
      flow,
      actionId,
    }: {
      flow?: FlowData | null;
      actionId: string;
    }): Omit<AppFilter, '__typename'> | undefined => {
      if (!flow) {
        throw new Error('Flow is required');
      }
      const defaultAppFilter: Omit<AppFilter, '__typename'> = {
        countryCode: CountryCodeType.Nl,
        tagCodes: [TagCodeType.Sendbanktransactions],
        condition: AndOrCondition.Or,
      };

      const action = getAction({ flow, actionId });

      if (!action) {
        return defaultAppFilter;
      }

      const config = action.settings ? JSON.parse(action.settings) : null;

      const appFilter = config?.appFilter || null;

      const { countryCode, tagCodes, condition } = appFilter ? appFilter : defaultAppFilter;

      return {
        countryCode,
        tagCodes,
        condition,
      };
    },
    [getAction],
  );

  const getActiveFlow = useCallback(
    async ({ shouldNavigate = true }: { shouldNavigate?: boolean }) => {
      try {
        const { data } = await getActiveFlowQuery({
          fetchPolicy,
          variables: { requestId },
        });

        if (!shouldNavigate) {
          return data;
        }

        const nextUrl = getNavigationUrl(data?.getActiveFlow as FlowData);
        navigate(nextUrl);
      } catch (error) {
        // TODO: improve error handling here as part of: https://minoxsoftware.atlassian.net/browse/BR-253
        if (error instanceof Error) logger.error(error.message);

        Sentry.captureException('Something went wrong while trying to get active flow');
        errorDialog.open(genericError);
      }
    },
    [getActiveFlowQuery, requestId, getNavigationUrl, navigate, errorDialog, genericError],
  );

  const getFlow = useCallback(
    async ({ flowId }: { flowId: string }) => {
      if (!isFlowIdValid(flowId)) {
        Sentry.captureException('Invalid flow id');
        errorDialog.open(invalidFlowId);
        return;
      }

      try {
        const { data } = await getFlowQuery({
          variables: { flowId, requestId },
          fetchPolicy: 'cache-and-network',
        });
        if (!data?.getFlow) throw new Error('Empty flow returned');
        const flow = data.getFlow as FlowData;

        const url = flow?.redirectUrl;

        setOrchestratorState((prevState) => {
          return produce(prevState, (newState) => {
            if (!newState) return newState;
            if (url) newState.redirectUrl = url;

            if (flow.requests) {
              newState.requesterRequests = flow.requests.map((request) => ({
                ...request,
              }));
            }

            const requestedFlowIsMainFlow =
              newState && newState.flow && newState.flow.id === flow.id;
            const requestedFlowIsSubFlow = !requestedFlowIsMainFlow;

            if (requestedFlowIsMainFlow) newState.flow = flow;

            if (requestedFlowIsSubFlow) {
              if (!newState.flow) return newState;
              const index = newState.flow.actions.findIndex((subFlow) => subFlow.id === flow.id);

              if (index < 0) {
                Sentry.captureException(`Flow id '${flow.id}' not found in global state`);
                errorDialog.open();
                return newState;
              }

              newState.flow.actions[index] = flow;
            }

            return newState;
          });
        });

        return data;
      } catch (error) {
        // TODO: improve error handling here as part of: https://minoxsoftware.atlassian.net/browse/BR-253
        if (error instanceof Error) logger.error(error.message);
      }
    },

    [errorDialog, invalidFlowId, getFlowQuery, requestId, setOrchestratorState],
  );

  const finishFlowAction = useCallback(
    async ({
      actionId,
      shouldNavigate = true,
      search,
      flowId,
      dynamicId,
    }: {
      actionId: string;
      shouldNavigate?: boolean;
      search?: string;
      flowId?: string;
      dynamicId?: string;
    }) => {
      const currentFlowId = orchestratorState?.flow?.id || flowId;
      if (!isFlowActionIdValid(actionId) || !currentFlowId) {
        Sentry.captureException('Invalid flow action id');
        errorDialog.open(invalidFlowActionId);
        return;
      }

      try {
        const { data } = await finishFlowActionMutation({
          variables: { flowId: currentFlowId, actionId, requestId, dynamicId },
        });

        if (data?.finishFlowAction) {
          setOrchestratorState((prevState) => ({
            ...prevState,
            flow: { ...data.finishFlowAction } as FlowData,
          }));
        }

        const nextUrl = `${getNavigationUrl(data?.finishFlowAction as FlowData)}${
          search ? `?${search}` : ''
        }`;

        if (!shouldNavigate) return nextUrl;

        const flowIsFinished = data?.finishFlowAction?.isFinished;

        if (flowIsFinished) {
          navigate(
            `${Paths.orchestrator.root}${Paths.orchestrator.segments.success}`.replace(
              ':flowId',
              currentFlowId,
            ),
          );
          return nextUrl;
        }

        navigate(nextUrl);

        return nextUrl;
      } catch (error) {
        // TODO: improve error handling here as part of: https://minoxsoftware.atlassian.net/browse/BR-253
        if (!(error instanceof Error)) return;

        logger.error(error.message);

        if (!error.message.includes('active flow')) {
          Sentry.captureException('Something went wrong while trying to finish the action');
        }
        return;
      }
    },
    [
      orchestratorState?.flow?.id,
      errorDialog,
      invalidFlowActionId,
      finishFlowActionMutation,
      requestId,
      getNavigationUrl,
      navigate,
      setOrchestratorState,
    ],
  );

  const updateActionState = useCallback(
    async ({
      flowId,
      actionId,
      state,
      requestId,
    }: {
      flowId: string;
      actionId: string;
      state?: string;
      requestId?: string;
    }) => {
      if (flowId && !isFlowIdValid(flowId)) {
        Sentry.captureException('Invalid flow id');
        errorDialog.open(invalidFlowId);
        return;
      }

      const result = await updateActionStateMutation({
        variables: {
          flowId,
          actionId: actionId,
          state: { state: state },
          ...(requestId && { requestId }),
        },
      });

      const hasError = !result.data || result.errors?.length;

      if (hasError) {
        Sentry.captureException('Something went wrong while trying to update action state');
        errorDialog.open(genericError);
        return;
      }

      const flowData = result?.data?.updateActionState?.flowData;

      if (!flowData) {
        Sentry.captureException('Empty flow data returned');
        errorDialog.open(genericError);
        return;
      }

      const url = flowData.redirectUrl;
      setOrchestratorState((prev) => ({
        ...prev,
        flow: flowData as FlowData,
        ...(url && { redirectUrl: url }),
      }));
    },

    [errorDialog, genericError, invalidFlowId, setOrchestratorState, updateActionStateMutation],
  );

  const navigateToAction = useCallback(
    async ({
      actionId,
      partnerId,
      flow,
      request,
      dynamicId,
    }: {
      actionId: string;
      flow: FlowData;
      request?: Request;
      partnerId?: string;
      dynamicId?: string;
    }) => {
      if (!flow) return false;

      const action = getAction({ flow, actionId });
      if (!action) return false;

      const triggers = action.triggers?.pre.filter((triggerAction) => triggerAction.execute);
      const executedTriggers: Promise<unknown>[] = [];

      triggers?.forEach((triggerAction) => {
        const mutation = `mutation ${triggerAction.mutation}($requestId: String) { ${triggerAction.mutation}(requestId: $requestId) }`;
        const promise = client.mutate({
          mutation: gql`
            ${mutation}
          `,
          variables: {
            ...(request && {
              requestId: request?.requestId,
            }),
          },
        });

        executedTriggers.push(promise);
      });

      await Promise.all(executedTriggers);

      triggers?.forEach((triggerAction) => {
        finishFlowAction({
          flowId: flow.id,
          actionId: triggerAction.actionId,
          shouldNavigate: false,
        }).then((success) => {
          if (!success) errorDialog.open();
        });
      });

      if (action.status === 'waiting' && request?.requestId) {
        const requestLink =
          `${Paths.orchestrator.root}${Paths.orchestrator.segments.request.root}${Paths.orchestrator.segments.request.segments.status}`.replace(
            ':requestId',
            request.requestId,
          );
        navigate(requestLink);
        return;
      }

      const actionUrl = new URL(action.url, window.location.origin);

      if (partnerId && actionUrl.pathname.includes(':partnerId')) {
        actionUrl.pathname = actionUrl.pathname.replace(':partnerId', partnerId);
      }

      const params = {
        ...(flow.id && { flowId: flow.id }),
        ...(actionId && { actionId }),
        ...(dynamicId && { dynamicId }),
      };
      Object.entries(params).forEach(([key, value]) => actionUrl.searchParams.append(key, value));

      const relativeUrl = actionUrl.pathname + actionUrl.search;
      navigate(relativeUrl);
    },
    [client, errorDialog, finishFlowAction, getAction, navigate],
  );

  const startFlow = useCallback(
    async ({
      flowId,
      requestId,
      shouldNavigate = true,
      search,
      redirectUrl,
      partnerId,
      flowFilter,
    }: {
      flowId: string;
      requestId?: string;
      redirectUrl?: string;
      shouldNavigate?: boolean;
      search?: string;
      partnerId?: string;
      flowFilter?: FlowFilter;
    }) => {
      if (!isFlowIdValid(flowId)) {
        Sentry.captureException('Invalid flow id');
        errorDialog.open(invalidFlowActionId);
        return;
      }

      try {
        const { data } = await startFlowMutation({
          variables: { flowId, redirectUrl, requestId },
          update: (cache, { data }) => {
            if (!data?.startFlow) return;

            cache.writeQuery({
              query: GetFlowDocument,
              data: {
                getFlow: data.startFlow,
              },
            });
          },
        });

        if (!data?.startFlow) return;
        const flow = data.startFlow as FlowData;

        setOrchestratorState((prevState) => {
          const newState = { ...prevState, flow, redirectUrl: redirectUrl || null };

          if (flow.requests) {
            newState.requesterRequests = flow.requests.map((request) => ({
              ...request,
            }));
          }

          return newState;
        });

        const updateActionPromises = [];
        const selectBankAction = getAction({ flow, actionId: 'select-bank' });
        if (selectBankAction) {
          const state = selectBankAction.state ? JSON.parse(selectBankAction.state) : {};

          updateActionPromises.push(
            updateActionState({
              actionId: 'select-bank',
              flowId: flowId,
              state: JSON.stringify({
                ...(!isEmpty(flowFilter?.banks) ? flowFilter?.banks : state),
                ...(typeof state.cddNeeded !== 'undefined' && { cddNeeded: state.cddNeeded }),
              }),
              requestId,
            }),
          );
        }

        const selectBankOptionAction = getAction({ flow, actionId: 'select-bank-option' });
        if (selectBankOptionAction) {
          const state = selectBankOptionAction.state
            ? JSON.parse(selectBankOptionAction.state)
            : {};

          updateActionPromises.push(
            updateActionState({
              actionId: 'select-bank-option',
              flowId: flowId,
              state: JSON.stringify({
                ...(!isEmpty(flowFilter?.banks) ? flowFilter?.banks : state),
                ...(typeof state.cddNeeded !== 'undefined' && { cddNeeded: state.cddNeeded }),
              }),
              requestId,
            }),
          );
        }

        if (flowFilter && Object.keys(flowFilter).length > 0) {
          if (!isEmpty(flowFilter.accountingSystems)) {
            updateActionPromises.push(
              updateActionState({
                actionId: 'select-accounting-system',
                flowId: flowId,
                state: JSON.stringify({ apps: flowFilter.accountingSystems }),
                requestId,
              }),
            );
          }

          if (flow && !isEmpty(flowFilter.psps) && getAction({ flow, actionId: 'select-psp' })) {
            updateActionPromises.push(
              updateActionState({
                actionId: 'select-psp',
                flowId: flowId,
                state: JSON.stringify({ apps: flowFilter.psps }),
                requestId,
              }),
            );
          }
        }
        await Promise.all(updateActionPromises);

        if (flow.flowType === 'linear') {
          const nextAction = getNextAction(flow);
          const completedActions = findActionsByStatus(flow.actions, [FlowStatus.Completed]);

          const nextAvailableSubFlow = getNextAvailableSubFlow(flow);
          if (
            nextAvailableSubFlow?.flowType === 'freeform' &&
            nextAvailableSubFlow.actions.some((action) => action.id === nextAction.id)
          ) {
            const nextUrl = getOverviewUrl(nextAvailableSubFlow.id);

            if (shouldNavigate) {
              navigate(nextUrl);
              return;
            }

            return { nextUrl };
          }

          if (!nextAction.id) {
            let nextUrl = getOverviewUrl(flow.id);
            if (flow.skipOverview && completedActions.length) nextUrl = completedActions[0].url;
            if (!flow.actions.length) nextUrl = getSuccessScreenUrl(flow.id);

            if (shouldNavigate) {
              navigate(nextUrl);
              return;
            }

            return { nextUrl };
          }

          if (shouldNavigate) {
            await navigateToAction({
              actionId: nextAction?.id,
              flow,
              request: orchestratorState?.approverRequest,
              partnerId,
            });
            return;
          }

          return { nextActionId: nextAction.id };
        }

        const queryParams = new URLSearchParams(search);
        const queryParamsString = queryParams.toString();
        const nextUrl =
          getOverviewUrl(flow.id) + (queryParamsString.length ? `?${queryParamsString}` : '');

        if (shouldNavigate) {
          navigate(nextUrl);
          return;
        }

        return { nextUrl };
      } catch (error) {
        // TODO: improve error handling here as part of: https://minoxsoftware.atlassian.net/browse/BR-253
        if (error instanceof Error) logger.error(error.message);
        Sentry.captureException('Something went wrong while trying to start flow');
        errorDialog.open(genericError);
      }
    },
    [
      errorDialog,
      invalidFlowActionId,
      startFlowMutation,
      setOrchestratorState,
      updateActionState,
      getAction,
      getNextAction,
      navigate,
      navigateToAction,
      orchestratorState?.approverRequest,
      genericError,
    ],
  );

  const getRequest = useCallback(
    async ({ requestId }: { requestId: string }) => {
      try {
        const response = await getRequestQuery({
          variables: {
            requestId: requestId,
          },
          fetchPolicy,
        });
        const data = response.data;

        setOrchestratorState((prevState) => {
          const request = { ...data?.getRequest } as RequestData;

          const isApprover = user.id === request?.approver?.id;

          if (
            request &&
            request.__typename === 'RequestData' &&
            !prevState?.requesterRequests?.some(
              (existingRequest) => existingRequest.requestId === request.requestId,
            )
          ) {
            delete request.__typename;

            if (requestId) {
              if (isApprover) {
                return {
                  ...prevState,
                  approverRequest: request,
                };
              }

              return {
                ...prevState,
                requesterRequests: [
                  ...(prevState?.requesterRequests || []),
                  {
                    ...request,
                  },
                ],
              };
            }
          }

          return prevState;
        });

        return data?.getRequest;
      } catch (error) {
        if (error instanceof Error) logger.error(error.message);
        Sentry.captureException('Something went wrong while trying to get request');
        errorDialog.open(genericError);
      }
    },
    [errorDialog, genericError, getRequestQuery, setOrchestratorState, user.id],
  );

  const updateRequest = useCallback(
    async ({
      status,
      requestId,
      reminderDateTime,
    }: {
      status: RequestStatus;
      requestId: string;
      reminderDateTime?: number;
    }) => {
      try {
        const { data } = await updateRequestMutation({
          variables: {
            status,
            requestId,
            reminderDateTime: reminderDateTime ? reminderDateTime : undefined,
          },
        });

        setOrchestratorState((prevState) => {
          let updatedRequests: Requests = [];

          if (prevState?.requesterRequests) {
            updatedRequests = (prevState?.requesterRequests || []).map((request) => {
              if (request.requestId === requestId) {
                return {
                  ...request,
                  status,
                  reminderDateTime,
                };
              }
              return request;
            });
          }

          return {
            ...prevState,
            requesterRequests: updatedRequests,
          };
        });

        return data?.updateRequest.result;
      } catch (error) {
        if (error instanceof Error) logger.error(error.message);
        Sentry.captureException('Something went wrong while trying to update request');
        errorDialog.open(genericError);
      }
    },
    [errorDialog, genericError, setOrchestratorState, updateRequestMutation],
  );

  const requestStatusCheck = useCallback(
    async (currentUserId: string) => {
      const request = orchestratorState?.approverRequest;
      const isRequester = request?.requester?.id === currentUserId;
      if (!request?.requestId || isRequester) return;
      if (getRequestProps.loading) return;

      const data = await getRequest({
        requestId: request.requestId,
      });

      if (data?.__typename !== 'RequestData') {
        logger.error('Get connection request in OrchestratorRouter fails');
        return;
      }

      const requestState = { ...data };
      delete requestState.__typename;

      setOrchestratorState((prevState) => ({
        ...prevState,
        approverRequest: {
          ...(requestState as Partial<RequestData>),
        },
      }));

      if (
        [RequestStatus.Available, RequestStatus.Completed].includes(data?.status as RequestStatus)
      ) {
        return;
      }

      navigate(
        Paths.orchestrator.root +
          Paths.orchestrator.segments.request.root +
          Paths.orchestrator.segments.request.segments.details.replace(
            ':requestId',
            request.requestId,
          ),
      );
      return;
    },
    [
      getRequest,
      getRequestProps.loading,
      navigate,
      orchestratorState?.approverRequest,
      setOrchestratorState,
    ],
  );

  const getNextUrls = useCallback((flow: FlowData) => {
    const successScreenUrl = getSuccessScreenUrl(flow.id);

    return [...getNextActionsInFlow(flow).map((action) => action.url), successScreenUrl];
  }, []);

  const getRequestActions = useCallback(
    (actionId: string) => {
      const action = getAction({ flow: orchestratorState?.flow as FlowData, actionId });

      return action?.requestActions ?? null;
    },
    [getAction, orchestratorState?.flow],
  );

  const getLastRequest = useCallback(
    (connectionType: string) => {
      const userRequests = orchestratorState?.requesterRequests;
      if (!userRequests || !userRequests.length) return;

      const sortedUserRequests = userRequests
        .filter((request) => request.connectionType === connectionType)
        .sort((a, b) => {
          return new Date(a.createdAt ?? 0).getTime() - new Date(b.createdAt ?? 0).getTime();
        });

      if (sortedUserRequests.length === 0) return;

      return sortedUserRequests[sortedUserRequests.length - 1];
    },
    [orchestratorState?.requesterRequests],
  );
  //endregion

  return {
    redirectUrl: orchestratorState?.redirectUrl || undefined,
    methods: {
      getActiveFlow,
      getAppFilter,
      getFlow,
      finishFlowAction,
      updateActionState,
      startFlow,
      navigateToAction,
      getRequest,
      updateRequest,
      requestStatusCheck,
      getNextUrls,
      getAction,
      sendRequest,
      getRequestActions,
      getLastRequest,
    },
    called: {
      finishFlowAction: finishFlowActionProps.called,
      getActiveFlow: getActiveFlowProps.called,
      getFlow: getFlowProps.called,
      startFlow: startFlowProps.called,
      getFlowConfig: getFlowProps.called,
      getRequest: getRequestProps.called,
      sendRequest: sendRequestProps.called,
      updateRequest: updateRequestProps.called,
      updateActionState: updateActionStateProps.called,
    },
    loading: {
      finishFlowAction: finishFlowActionProps.loading,
      getActiveFlow: getActiveFlowProps.loading,
      getFlow: getFlowProps.loading,
      startFlow: startFlowProps.loading,
      getFlowConfig: getFlowProps.loading,
      getRequest: getRequestProps.loading,
      sendRequest: sendRequestProps.loading,
      updateRequest: updateRequestProps.loading,
      updateActionState: updateActionStateProps.loading,
    },
    error: {
      finishFlowAction: finishFlowActionProps.error,
      getActiveFlow: getActiveFlowProps.error,
      getFlow: getFlowProps.error,
      startFlow: startFlowProps.error,
      getFlowConfig: getFlowProps.error,
      getRequest: getRequestProps.error,
      sendRequest: sendRequestProps.error,
      updateRequest: updateRequestProps.error,
      updateActionState: updateActionStateProps.error,
    },
  } satisfies UseOrchestratorResponse;
};
