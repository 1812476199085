import { flows, RenewBankConsentFlows } from './MockData';

export enum DemoNames {
  UniversalPartner = 'Universal Demo Partner',
  TestPartner = 'Test Partner',
  AsnBoekhoudkoppelingAcc = 'ASN boekhoudkoppeling - Demo',
  SnsBoekhoudkoppelingAcc = 'SNS boekhoudkoppeling - Demo',
  RegioBankBoekhoudkoppelingAcc = 'RegioBank boekhoudkoppeling - Demo',
  AsnBoekhoudkoppelingProd = 'ASN boekhoudkoppeling',
  SnsBoekhoudkoppelingProd = 'SNS boekhoudkoppeling',
  RegioBankBoekhoudkoppelingProd = 'RegioBank boekhoudkoppeling',
  ConnectAccountingSystemMollieApAr = 'Mollie boekhoudkoppeling - Demo',
}

export interface UserPayloadData {
  partner_reference: string;
  initials: string;
  first_name: string;
  last_name: string;
  terms_accepted: boolean;
  billing_accepted: boolean;
  verified_user: boolean;
  date_of_birth: string;
  company_name: string;
  phone_number: string;
  embedded_user: boolean;
  external_id?: string;
  email: Nullable<string>;
}

export type BasicAdministration = {
  name: string;
  send_ubl: boolean;
  kvk_number: string;
};

export type BasicBankAccount = {
  api_account?: boolean;
  iban?: string;
  display_name: string;
  currency: string;
};

export interface VerifiedUserPayloadData {
  user: UserPayloadData;
  bizcuit_customer?: {
    business_account: boolean;
    billing_address: {
      name: string;
      street: string;
      street2: string;
      postal_code: string;
      city: string;
      province: string;
      country: string;
    };
    iban: string;
  };
  bank_account?: BasicBankAccount;
  administration?: BasicAdministration;
}

export interface InitiateFlowResponse {
  id: string;
  redirect: string;
}

export interface PocSetup {
  userId: string;
  partnerId: string;
  partnerSecret: string;
}

export interface JwtPayload {
  exp: number;
  iat: number;
  userId: string;
  partnerId: string;
  flowId: string;
}

export type CreateAdministrationResponse = {
  id: string;
};

export type CreateBankAccountResponse = {
  id: string;
};

export type FlowId = keyof typeof flows | keyof typeof RenewBankConsentFlows;
export type FlowName =
  | (typeof flows)[keyof typeof flows]
  | (typeof RenewBankConsentFlows)[keyof typeof RenewBankConsentFlows];

export type SelectFlowOption = {
  label: FlowName;
  value: FlowId;
  selected: boolean;
};

export type DefaultUserData = {
  [key in DemoNames]: {
    userId: string;
    email: string;
    icon: string;
    validFlows: FlowId[];
    bankConnectors?: string;
    partnerId: string;
    partnerSecret: string;
    prodOnly: boolean;
  };
};
