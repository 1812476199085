import { FlowLayout } from 'src/atoms/layout/menu-page';
import { useLocation, useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import SecondaryButton from 'src/atoms/buttons/secondary-button/SecondaryButton';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import AlertDialog from 'src/organisms/dialog/AlertDialog';
import { useTranslation } from 'react-i18next';
import TertiaryButton from 'src/atoms/buttons/tertiary-button/TertiaryButton';
import Container from 'src/atoms/layout/container/Container';
import Checkbox from 'src/atoms/inputs/checkbox/Checkbox';
import Paths from 'src/tokens/Paths';
import { parseQueryParamsString } from 'src/helpers';
import { useCreateUbos, useGetCustomersAndUbos, useGetDirectors } from 'src/types/bizcuitApi';
import { useErrorDialog } from 'src/hooks';
import SkeletonCopyUboFromDirectorLoader from 'src/atoms/loaders/skeleton-loader/SkeletonCopyUboFromDirectorLoader';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';

const UbosFromDirectors = () => {
  const location = useLocation();
  const { type } = useParams();
  const { dynamicId } = parseQueryParamsString(location.search);
  const [showWhatIsUbo, setShowWhatIsUbo] = useState(false);
  const [selectedDirectors, setSelectedDirectors] = useState<string[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation(['cdd', 'common']);
  const errorDialog = useErrorDialog();

  const {
    data: getCustomersAndUbosData,
    loading: getCustomersAndUbosLoading,
    error: getCustomersAndUbosError,
  } = useGetCustomersAndUbos({ fetchPolicy: 'network-only' });

  const {
    data: directorsData,
    loading: directorsLoading,
    error: directorsError,
  } = useGetDirectors({
    variables: {
      customerId: dynamicId,
    },
  });

  const [createUbos, { loading: createUbosLoading, error: createUbosError }] = useCreateUbos();

  useEffect(() => {
    if (getCustomersAndUbosError || directorsError || createUbosError) {
      errorDialog.open();
    }
  }, [createUbosError, directorsError, errorDialog, getCustomersAndUbosError]);

  const customer = getCustomersAndUbosData?.getCustomers.find(
    (customer) => customer.id === dynamicId,
  );

  if (type !== 'ubo' && type !== 'pseudoUbo') {
    const errorMessage = `Invalid type: ${type}. Expected 'ubo' or 'pseudoUbo'.`;

    errorDialog.open();
    Sentry.captureException(errorMessage);

    throw new Error(errorMessage);
  }

  const descriptionTranslations = t(`cdd:ubosFromDirectors.${type}.description`, {
    returnObjects: true,
  });

  const handleProceed = async () => {
    const directorsToCopy = directorsData?.getDirectors
      .filter((director) => selectedDirectors.includes(director.fullName))
      .map((director) => ({
        firstName: director.firstName,
        lastName: director.lastName,
        dateOfBirth: director.dateOfBirth || null,
        nationality: '',
        countryOfResidence: '',
      }));

    if (!directorsToCopy || directorsToCopy.length === 0) return;

    const { errors } = await createUbos({
      variables: {
        customerId: dynamicId,
        isPseudoUbo: type !== 'ubo',
        uboDetails: directorsToCopy,
      },
    });

    if (errors) {
      Sentry.captureException(`Create ubo's failed`);

      errorDialog.open();
      return;
    }

    navigate(
      `${Paths.ais.root}${Paths.ais.segments.cdd.root}${Paths.ais.segments.cdd.segments.ubosInProgress}?dynamicId=${dynamicId}`.replace(
        ':type',
        type,
      ),
    );
  };

  const isDisabled = () => {
    return !selectedDirectors.length || createUbosLoading;
  };

  useEffect(() => {
    if (!directorsLoading && !directorsData?.getDirectors.length)
      navigate(
        `${Paths.ais.root}${Paths.ais.segments.cdd.root}${Paths.ais.segments.cdd.segments.ubosInProgress}?dynamicId=${dynamicId}`.replace(
          ':type',
          type,
        ),
      );
  }, [directorsData?.getDirectors.length, directorsLoading, dynamicId, navigate, type]);

  const toggleDirector = (directorFullName: string) => {
    setSelectedDirectors((prevState) => {
      if (!prevState.includes(directorFullName)) {
        return [...prevState, directorFullName];
      }
      return prevState.filter((name) => name !== directorFullName);
    });
  };

  return (
    <FlowLayout
      pageHeaderText={t('cdd:shortTitle')}
      spaceBetween={false}
      footer={
        <footer className="flex pb-6 items-center w-full flex-wrap flex-col">
          <hr className="absolute top-0 bg-gradient-to-b from-transparent to-primary-100 dark:to-primary-800 dark:opacity-40 h-4 w-full -mt-4 -ml-4 dark:h-2 dark:-mt-2 md:ml-0 border-none" />
          <SecondaryButton className="mt-4 md:mt-6" underline>
            {t('common:button.noThankYou')}
          </SecondaryButton>
          <PrimaryButton
            className="md:w-80"
            isDisabled={isDisabled()}
            onClick={() => handleProceed()}
            showSpinner={createUbosLoading}
          >
            {t('common:button.continue')}
          </PrimaryButton>
        </footer>
      }
    >
      {getCustomersAndUbosLoading || directorsLoading || !directorsData?.getDirectors.length ? (
        <SkeletonCopyUboFromDirectorLoader items={2} />
      ) : (
        <>
          <h1 className="heading-100 my-6 text-warm-grey-800 dark:text-grey-100">
            {t(`cdd:ubosFromDirectors.${type}.primaryHeader`)}
          </h1>
          <div className="flex flex-col">
            {descriptionTranslations.map((description, index) => (
              <p key={index} className={`text-medium-loose${index > 0 ? ' pt-6' : ''}`}>
                {description}
              </p>
            ))}
            <TertiaryButton
              className="mt-2 mb-6"
              color="gray-800"
              underline
              onClick={() => setShowWhatIsUbo(true)}
            >
              {t(`cdd:whatIsUboDialog.${type}.title`)}
            </TertiaryButton>
            <div className="flex p-4 justify-between mb-6">
              <p className="text-medium font-weight-500">{customer?.name}</p>
              <div className="flex gap-1 text-x-small items-center">
                <p className="font-weight-500">{t('common:chamberOfCommerceShortHand')}</p>
                <p>{customer?.kvkNumber}</p>
              </div>
            </div>
            <p className="text-x-small font-weight-500 text-biz-gray-600 dark:text-white mb-2">
              {t('common:directors')}
            </p>
            <div className="grid md:grid-cols-2 gap-2">
              {directorsData?.getDirectors.map((director, index) => (
                <Container
                  roundedClass="rounded-2xl"
                  key={index}
                  className="hover:cursor-pointer"
                  onClick={() => {
                    toggleDirector(director.fullName);
                  }}
                >
                  <div className="flex gap-4 items-center">
                    <Checkbox
                      name="check"
                      isChecked={selectedDirectors.includes(director.fullName)}
                      onChecked={() => {
                        toggleDirector(director.fullName);
                      }}
                      onUnchecked={() => {
                        toggleDirector(director.fullName);
                      }}
                    />
                    <p className="text-small truncate">{`${director.fullName}`}</p>
                  </div>
                </Container>
              ))}
            </div>
          </div>
        </>
      )}
      <AlertDialog
        isVisible={showWhatIsUbo}
        onClose={() => setShowWhatIsUbo(!showWhatIsUbo)}
        alertDialogTexts={t(`cdd:whatIsUboDialog.${type}`, { returnObjects: true })}
      />
    </FlowLayout>
  );
};

export default UbosFromDirectors;
