import React, { FC } from 'react';

type SkeletonCopyUboFromDirectorLoaderProps = {
  items?: number;
};
const SkeletonCopyUboFromDirectorLoader: FC<SkeletonCopyUboFromDirectorLoaderProps> = ({
  items = 3,
}) => (
  <div className="flex flex-col gap-2 animate-pulse">
    <div className="mt-10 mb-7 h-5 w-1/3 rounded-xl bg-primary-200 dark:bg-primary-800" />
    <div className="mb-1 md:mb-5 h-4 w-3/4 rounded-xl bg-primary-200 dark:bg-primary-800" />
    <div className="mb-1 md:mb-2 h-4 w-3/4 rounded-xl bg-primary-200 dark:bg-primary-800" />
    <div className="md:hidden mb-2 h-4 w-1/2 rounded-xl bg-primary-200 dark:bg-primary-800" />
    <div className="ml-4 h-3 w-1/6 rounded-xl bg-primary-200 dark:bg-primary-800" />
    <div className="flex py-10 p-4 justify-between">
      <div className="h-5 w-1/4 rounded-xl bg-primary-200 dark:bg-primary-800" />
      <div className="flex gap-1 text-x-small items-center">
        <div className="h-3 w-5 rounded-xl bg-primary-200 dark:bg-primary-800" />
        <div className="h-3 w-12 rounded-xl bg-primary-200 dark:bg-primary-800" />
      </div>
    </div>
    <div className="h-3 w-1/6 rounded-xl bg-primary-200 dark:bg-primary-800" />
    <div className="grid md:grid-cols-2 gap-2">
      {Array.from({ length: items }, (_, index) => (
        <div
          key={index}
          className="pt-5 bg-biz-gray-100 dark:bg-biz-gray-700 rounded-2xl h-12.5 w-full"
        />
      ))}
    </div>
  </div>
);

export default SkeletonCopyUboFromDirectorLoader;
