import { FC, useCallback, useEffect, useState, MouseEvent, ChangeEvent } from 'react';
import { ReactComponent as CheckIcon } from 'src/assets/images/icons/check.svg';
import { ReactComponent as UndeterminedIcon } from 'src/assets/images/icons/undetermined.svg';
import CheckboxProps from './Checkbox.types';
import { ComponentTestIdKeys } from 'src/tokens';

const Checkbox: FC<CheckboxProps> = ({
  name,
  children,
  onChecked,
  onUnchecked,
  isCentered,
  isChecked,
  isUndetermined,
  testId,
}) => {
  const [isCheckedBox, setIsCheckedBox] = useState(isChecked);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    setIsCheckedBox(isCheckedBox);
  }, [isCheckedBox]);

  useEffect(() => {
    setIsCheckedBox(isChecked ? isChecked : false);
  }, [isChecked]);

  const onClick = useCallback(
    (event: MouseEvent | ChangeEvent, checked: boolean) => {
      event.stopPropagation();
      setIsCheckedBox(checked);

      if (checked) onChecked();
      else if (onUnchecked) onUnchecked();
    },
    [onChecked, onUnchecked],
  );
  return (
    <div className={'flex ' + (isCentered ? 'items-center' : '')}>
      <div
        className={
          'w-5 h-5 shadow-inner rounded cursor-pointer mr-2 flex-none ' +
          (isCheckedBox
            ? `bg-primary-500`
            : `bg-biz-gray-200 border focus:outline-primary-400 ${
                isFocus ? 'ring-2 ring-primary-400 ' : ''
              } `)
        }
        onClick={(e) => onClick(e, !isCheckedBox)}
      >
        <input
          data-testid={testId ? `${ComponentTestIdKeys.Input}-${testId}` : ''}
          type="checkbox"
          id={name}
          className="fixed opacity-0 -z-10 w-1 h-1"
          onChange={(e) => onClick(e, !isCheckedBox)}
          checked={isChecked}
          onFocus={() => {
            setIsFocus(true);
          }}
          onBlur={() => {
            setIsFocus(false);
          }}
        />
        {isUndetermined && <UndeterminedIcon className="ml-auto mr-auto fill-primary-500" />}
        {!isUndetermined && isCheckedBox && (
          <CheckIcon className="ml-auto mr-auto mt-0.5 fill-primary-500" />
        )}
      </div>
      <div className="text-xs-plus font-weight-500 text-biz-gray-800 dark:text-biz-gray-200 w-11/12 flex-grow">
        <label htmlFor={name} className="hover:cursor-pointer">
          {children}
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
