import { FC, InputHTMLAttributes } from 'react';

type RadioButtonProps = InputHTMLAttributes<HTMLInputElement>;

const RadioButton: FC<RadioButtonProps> = ({ children, name, value, checked, onChange }) => (
  <label className="cursor-pointer flex">
    <input
      type="radio"
      onChange={onChange}
      value={value}
      name={name}
      checked={checked}
      className="appearance-none absolute"
    />
    <div
      className={`flex items-center justify-center w-5 h-5 rounded-full mr-4 ${
        checked ? 'border border-primary-500' : 'border-2 border-biz-gray-600'
      }`}
    >
      {checked && <div className="rounded-full bg-primary-500 w-3 h-3" />}
    </div>
    {children}
  </label>
);

export default RadioButton;
